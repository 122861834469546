@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();

@import 'light.theme';
@import 'dark.theme';

// Disable duplication config warnings
$mat-theme-ignore-duplication-warnings: true;

//Themes
.light-mode {
    @include mat.all-legacy-component-themes($app-light-theme);

    // Use class to set the text color to current theme primary color
    .theme-primary-color-text {
        color: mat.get-color-from-palette($light-theme-primary-palette) !important;
    }

    // Use class to set the text color to current theme accent color
    .theme-accent-color-text {
        color: mat.get-color-from-palette($light-theme-accent-palette) !important;
    }

    // Use class to set the text color to current theme warn color
    .theme-warn-color-text {
        color: mat.get-color-from-palette($light-theme-warn-palette) !important;
    }

    // Use class to set the background color to current theme primary color
    .theme-primary-color-background {
        background-color: mat.get-color-from-palette($light-theme-primary-palette) !important;
    }

    // Use class to set the background color to current theme accent color
    .theme-accent-color-background {
        background-color: mat.get-color-from-palette($light-theme-accent-palette) !important;
    }

    // Use class to set the background color to current theme warn color
    .theme-warn-color-background {
        background-color:  mat.get-color-from-palette($light-theme-warn-palette) !important;
    }

    // Matte green highlight background color
    .matte-green-background {
        background-color: #F1F2EB !important;
    }

    // Matte orange highlight background color
    .matte-orange-background {
        background-color: #DD6E421A !important;
    }

    // Matte blue highlight background color
    .matte-blue-background {
        background-color: #00FDDC1A !important;
    }

    // Change default color for links
    a {
        color: mat.get-color-from-palette($light-theme-accent-palette);
    }

    // Change the base color for mat-progress-bar (bug fix)
    .mat-progress-bar .mat-progress-bar-buffer {
        background-color: mat.get-color-from-palette($light-theme-accent-palette) !important;
    }

    // Project/Course create forms tooltip mat card background color
    .tooltip {
        mat-card {
            background-color: #F1F2EB !important;
        }
    }

}

.dark-mode {
    @include mat.all-legacy-component-themes($app-dark-theme);

    background-color: #333333;
    color: whitesmoke;


    // Use class to set the text color to current theme primary color
    .theme-primary-color-text {
        color: mat.get-color-from-palette($dark-theme-primary-palette) !important;
    }

    // Use class to set the text color to current theme accent color
    .theme-accent-color-text {
        color: mat.get-color-from-palette($dark-theme-accent-palette) !important;
    }

    // Use class to set the text color to current theme warn color
    .theme-warn-color-text {
        color: mat.get-color-from-palette($dark-theme-warn-palette) !important;
    }

    // Use class to set the background color to current theme primary color
    .theme-primary-color-background {
        background-color: mat.get-color-from-palette($dark-theme-primary-palette) !important;
    }

    // Use class to set the background color to current theme accent color
    .theme-accent-color-background {
        background-color: mat.get-color-from-palette($dark-theme-accent-palette) !important;
    }

    // Use class to set the background color to current theme warn color
    .theme-warn-color-background {
        background-color:  mat.get-color-from-palette($dark-theme-warn-palette) !important;
    }

    // Matte green highlight background color
    .matte-green-background {
        background-color: #5e5e5e !important;
    }

    // Matte orange highlight background color
    .matte-orange-background {
        background-color: #5e5e5e !important;
    }

    // Matte blue highlight background color
    .matte-blue-background {
        background-color: #5e5e5e !important;
    }

    // Change default color for links
    a {
        color: mat.get-color-from-palette($dark-theme-accent-palette);
    }

    // Change the base color for mat-progress-bar (bug fix)
    .mat-progress-bar .mat-progress-bar-buffer {
        background-color: mat.get-color-from-palette($dark-theme-accent-palette) !important;
    }

    // Project/Course create forms tooltip mat card background color
    .tooltip {
        mat-card {
            background-color: #5e5e5e !important;
        }
    }

}

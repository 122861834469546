/* You can add global styles to this file, and also import other style files */
@import "app/themes/themes";
@import "~simplebar/dist/simplebar.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";  /* required */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    height: 100%;
    overflow: auto;
}

body {
    margin: 0;
    font-family: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    background-color: rgba(47, 42, 149, 0.03);
}

//For putting elements in different edges in display flex
.example-spacer {
    flex: 1 1 auto;
}

.wrapper-container {
    max-width: 1400px !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: color 9999s ease-out, background-color 5000s ease-in-out 0s;
}

#soc-custom-loading-screen {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99999;
    pointer-events: none;
}

// Custom loading screen
#soc-custom-loading-screen img {
    position: absolute;
    overflow: hidden;
    left: 50%;
    top: 50%;

    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

// Global class for all tables that have rows which can be clicked
.clickable-row {
    cursor: pointer;

    &:hover {
        height: 60px;
    }
}
